<template>
<b-container fluid>
  <bo-page-title />

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col lg="5">
          <h5 class="card-title">{{ pageTitle }} List </h5>
        </b-col>
        <b-col lg="3">
          <!-- <b-form-group>
            <v-select placeholer="Select Status" v-model="selected_status" :options="status"></v-select>
          </b-form-group> -->
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :per-page="perPage"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template
          #cell(bul_is_active) = "data"
        >
          <b-badge
            v-if="data.value=='Y'"
            variant="success"
          >Active</b-badge>
          <b-badge
            v-else
            variant="danger"
          >Inactive</b-badge>
        </template>
        <template
          #cell(action)="data"
        >
          <b-button
            v-if="moduleRole('edit')"

            v-b-tooltip.hover
            title="Edit"
            class="btn-icon"
            variant="outline-primary"
            size="sm"
            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
          >
            <i class="ti-marker-alt"></i>
          </b-button>
          <b-button
            v-if="moduleRole('changeStatus')"

            v-b-tooltip.hover
            title="Change Status"
            class="btn-icon"
            variant="outline-info"
            size="sm"
            @click="doChangeStatus(data.index, data.item)"
          >
            <i class="ti-settings"></i>
          </b-button>
          <b-button
            v-if="moduleRole('delete')"

            v-b-tooltip.hover
            title="Delete"
            class="btn-icon"
            variant="outline-danger"
            size="sm"
            @click="doDelete(data.index, data.item)"
          >
            <i class="ti-trash"></i>
          </b-button>

        </template>
      </b-table>
    </b-card-body>
    <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
      <b-pagination
      class="mb-0"
      v-model="pageNo"
      :per-page="data.per_page"
      :total-rows="data.total"
      />
    </b-card-footer>
  </b-card>

  <template v-else>
    <Form :row.sync="row" v-bind="passToSub" />
  </template>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'bul_id',
      statusKey:'bul_is_active',
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'bul_name', label: 'Role Name',
        },
        {
          key: 'bul_is_active', label: 'Status', },
        {
          key: 'action', 
        },
      ],
      mrMenuRel:[],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{}, {
        mrMenuRel:this.mrMenuRel,
      })
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>